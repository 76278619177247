
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    return_type: String,
  },
  created() {
    this.fetchData();
    this.timer = setInterval(this.fetchData, 1000);
  },
  methods: {
    async fetchData() {
      const res = await fetch(this.request_url);
      if (this.return_type === "json") {
        const data = await res.json();
        if (data.result !== null) {
          this.time = data.result;
          this.message = `Time in Melbourne Victoria Australia in four hours:`;
        } else {
          this.message = "oopise whoopise i made a fucky wucky";
          this.time = null;
        }
      } else {
        this.message = await res.text();
        this.time = null;
      }
    },
  },
  data() {
    return {
      message: null,
      time: true,
      request_url: `https://tigtbifhimva.sarda.dev/api/v1/time_in_4_hours?return_type=${this.return_type}`,
    };
  },
})
export default class TIFH extends Vue {}
